<template>
  <Content />
</template>

<script>
import Content from './components/Content.vue'

export default {
  name: 'App',
  components: {
    Content
  }
}
</script>

<style>
body {
  background-color: black;
}
img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 100px;
  width: 50%;
}
</style>
